<template>
  <div class="tile is-parent">
    <article class="tile is-child box">
      <div class="columns">
        <div class="column">
          <p class="title">Sublet</p>
        </div>
        <div class="column">
          <div class="control is-pulled-right">
            <button class="button is-primary tooltip is-tooltip"
              data-tooltip="Add new sublet"
              @click="isAvailableItemsModuleActive = !isAvailableItemsModuleActive">
              <span class="icon">
                <i class="mdi"
                  :class="{ 'mdi-plus' : !isAvailableItemsModuleActive,
                            'mdi-close' : isAvailableItemsModuleActive }" />
              </span>
              <span>{{ isAvailableItemsModuleActive ? 'Close' : 'Add' }}</span>
            </button>
          </div>
        </div>
      </div>

      <div v-if="isAvailableItemsModuleActive">
        <quote-template-available-sublets
          v-model="items"
          labour-type="FQT"
          :is-audanet="false"
          @item-added="addNewItem" />

        <hr>
      </div>

      <bulma-table class="table is-bordered is-striped is-narrow is-fullwidth"
        :columns="columns"
        :is-loading="isTableLoading"
        :page-index="filter.pageIndex"
        :page-size="filter.pageSize"
        :sort-column="filter.sortColumn"
        :sort-order="filter.sortOrder"
        :total-rows="totalRows"
        :show-pagination="false"
        @sort="sort"
        :draggable="true">

        <draggable v-model="items"
          v-bind="{chosen: 'chosen', ghostClass: 'draggable-ghost', dragClass: 'draggable-drag'}"
          :tag="'tbody'"
          @change="itemMoved"
          @end="endMove"
          handle=".item-drag-handle">

          <tr v-for="(item, index) in items"
            :key="index">
            <td class="has-vertical-middle">{{ item.itemNo }}</td>
            <td>
              <input v-model="item.itemDesc"
                type="text"
                class="input">
            </td>
            <td class="has-vertical-middle">
              <div class="pretty p-icon p-curve p-smooth m-0 p-bigger">
                <input v-model="item.reportOnly"
                  type="checkbox">
                <div class="state p-info">
                  <i class="icon mdi mdi-check" />
                  <label />
                </div>
              </div>
            </td>
            <td>
              <vue-numeric
                class="input has-text-left"
                v-model.number="item.value"
                :precision="2" />
            </td>
            <td class="has-vertical-middle has-text-centered is-content-width">
              <div class="icon-container items-center">
                <button class="button is-danger is-small is-inverted mr-1"
                  @click="deleteItem(item)">
                  <span class="icon is-small">
                    <i class="mdi mdi-delete mdi-24px" />
                  </span>
                </button>
                <button class="button is-primary is-small is-inverted item-drag-handle">
                  <span class="icon is-small">
                    <i class="mdi mdi-drag-vertical mdi-24px" />
                  </span>
                </button>
              </div>
            </td>
          </tr>
        </draggable>

        <template slot="empty">
          <section class="section">
            <div class="content has-text-grey has-text-centered">
              <span icon="icon is-large">
                <i class="mdi mdi-48px mdi-emoticon-sad" />
              </span>
              <p>Nothing</p>
            </div>
          </section>
        </template>
      </bulma-table>
    </article>
  </div>
</template>

<script>
import BulmaTable from '@/components/BulmaTable'
import { SubletColumns } from './columns.js'
import { ItemCategoryTypes } from '@/enums'
import QuoteTemplateAvailableSublets from './QuoteTemplateAvailableSublets.vue'
import { NumberFiltersMixin } from '@/components/mixins/filters'
import QuoteTemplateItemMixin from './QuoteTemplateItemMixin'
import VueNumeric from '@/components/VueNumeric'
import draggable from 'vuedraggable'
import { QuoteTemplateItemModel } from '@/classes/viewmodels'
import Guid from '@/components/Guid'
import QuoteTemplateService from '@/services/QuoteTemplateService'

export default {
  name: 'QuoteTemplateSubletView',
  components: {
    BulmaTable,
    draggable,
    QuoteTemplateAvailableSublets,
    VueNumeric
  },
  mixins: [ NumberFiltersMixin, QuoteTemplateItemMixin ],
  data() {
    return {
      itemCategoryTypes: [ ItemCategoryTypes.SUBL ]
    }
  },
  computed: {
    columns() {
      return SubletColumns
    }
  },
  methods: {
    async addNewItem(item) {
      const currentMaxSortIndex = Math.max(...this.items.map((i) => i.sortNo))
      let newItems = []
      let newItemData = {
        tempateId: this.$route.params.entityId,
        itemId: Guid.empty(),
        itemNo: item.itemNo,
        itemDesc: item.itemDesc,
        itemType: item.itemType,
        value: item.value,
        reportOnly: false,
        sortNo: this.items.length > 0 ? currentMaxSortIndex + 1 : 0
      }

      const newItem = new QuoteTemplateItemModel(newItemData)
      newItems.push(newItem)

      await QuoteTemplateService.insertNewQuoteTemplateItems(this.templateId, newItems)
      await this.getQuoteTemplateItems()
    }
  }
}
</script>
