<template>
  <div>
    <div class="columns is-gapless">
      <div class="column">
        <div class="field">
          <div class="field is-horizontal">
            <div class="field-body">
              <div class="field-body">
                <div class="field has-addons">
                  <div class="control has-icons-left has-icons-right">
                    <input class="input"
                      v-model="filter.query"
                      type="text"
                      placeholder="Start typing to filter"
                      @input="search">
                    <span class="icon is-left">
                      <i class="mdi mdi-magnify mdi-18px" />
                    </span>
                  </div>
                  <div class="control">
                    <a class="button is-primary is-outlined tooltip"
                      data-tooltip="Reset search"
                      @click="reset()">
                      <span class="icon">
                        <i class="mdi mdi-close mdi-24px" />
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="$userInfo && $userInfo.countryCode.localeCompare('NZ', $userInfo.locale, {sensitivity: 'accent'}) === 0"
        class="column">
        <div class="tabs is-toggle is-pulled-right">
          <ul>
            <li :class="{ 'is-active' : activeTab === subletTypes.standard }">
              <a @click="tabClicked(subletTypes.standard)">
                <span>Standard</span>
              </a>
            </li>
            <li :class="{ 'is-active' : activeTab === subletTypes.veronz }">
              <a @click="tabClicked(subletTypes.veronz)">
                <span>Vero-NZ</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <bulma-table class="table is-bordered is-striped is-narrow is-fullwidth"
      :columns="columns"
      :page-index="filter.pageIndex"
      :page-size="filter.pageSize"
      :total-rows="totalRows"
      :is-loading="isTableLoading"
      :sort-column="filter.sortColumn"
      :sort-order="filter.sortOrder"
      @pageChanged="onPageChange"
      @sort="sort">
      <tr v-for="(item, index) in availableItems"
        :key="index"
        @dblclick="addNewItem(item, index)"
        :class="{ 'is-selected' : selectedRow === index }"
        @click="highlightSelected(index, $event)">
        <td>{{ item.itemNo }}</td>
        <td>{{ item.description }}</td>
        <td class="has-text-right">{{ item.price | formatNumber($userInfo.locale) }}</td>
        <td class="has-vertical-middle has-text-centered is-content-width">
          <a class="button is-primary is-small is-inverted"
            @click="addNewItem(item, index)">
            <span class="icon is-medium">
              <i class="mdi mdi-plus mdi-24px" />
            </span>
          </a>
        </td>
      </tr>
      <template slot="empty">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <span icon="icon is-large">
              <i class="mdi mdi-48px mdi-emoticon-sad" />
            </span>
            <p>Nothing</p>
          </div>
        </section>
      </template>
    </bulma-table>
  </div>
</template>

<script>
import QuoteService from '@/views/quote/QuoteService'
import { AvailableSubletTypes } from '@/views/quote/QuoteService'
import { AvailableSubletColumns } from '@/views/quote/columns'
import { ItemCategoryTypes } from '@/enums'
import BulmaTable from '@/components/BulmaTable'
import _debounce from 'lodash.debounce'
import Guid from '@/components/Guid'
import { NumberFiltersMixin } from '@/components/mixins/filters'
import { QuoteItemModel } from '@/classes/viewmodels'

export default {
  name: 'QuoteTemplateAvailableSublets',
  components: {
    BulmaTable
  },
  filters: {},
  mixins: [NumberFiltersMixin],
  props: {
    value: {
      type: Array,
      default: function() {
        return []
      }
    },
    quoteId: {
      type: String,
      default: ''
    },
    labourType: {
      type: String,
      default: ''
    },
    nextLineNumber: {
      type: Number,
      default: 0
    },
    pageSize: {
      type: Number,
      default: 8
    },
    isAudanet: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      innerValue: null,
      selectedRow: null,
      isTableLoading: false,
      totalRows: 0,
      filter: {
        countryId: Guid.empty(),
        query: '',
        sortColumn: '',
        sortOrder: '',
        pageIndex: 1,
        pageSize: this.pageSize
      },
      availableItems: null,
      selectedItems: [],
      activeTab: ''
    }
  },
  computed: {
    columns() {
      return AvailableSubletColumns
    },
    newItems() {
      return this.innerValue.filter(i => i.isNew && !i.deleted)
    },
    subletTypes() {
      return AvailableSubletTypes
    }
  },
  created() {
    this.activeTab = this.subletTypes.standard
    this.getAvailableSublets()
  },
  mounted() {},
  methods: {
    async getAvailableSublets() {
      this.isTableLoading = true
      this.availableItems = await QuoteService.getAvailableSubletItems(this.filter, this.activeTab)
      this.totalRows = this.availableItems.length > 0 ? this.availableItems[0].totalRows : 0
      this.isTableLoading = false
    },
    highlightSelected(index, event) {
      this.selectedRow = index
    },
    onPageChange(pageIndex) {
      this.filter.pageIndex = pageIndex
      this.getAvailableSublets()
    },
    sort(name, order) {},
    reset() {
      this.selectedItems = []
      this.filter.query = ''
      this.filter.pageIndex = 1
      this.getAvailableSublets()
    },
    search: _debounce(function() {
      this.filter.pageIndex = 1
      this.getAvailableSublets()
    }, 500),
    cancel(value) {
      this.selectedItems = value
    },
    addNewItem(item, index) {
      const existingItem = this.value.find(i => i.itemDesc === item.description && i.itemNo === item.itemNo && !i.deleted && !i.deleteOtherItem)
      if (!existingItem) {
        let newItem = new QuoteItemModel(this.quoteId, item.itemNo, item.description, ItemCategoryTypes.SUBL)
        newItem.lineNumber = !this.isAudanet ? this.nextLineNumber : 0
        newItem.value = item.price
        newItem.sortNo = this.value.length ? Math.max(...this.value.map(i => i.sortNo)) + 1 : 1
        this.$emit('item-added', newItem)
      } else {
        this.$notification.openNotificationWithType('warning', 'Sublet', 'Item is already in selected list. Item not added.')
      }
    },
    tabClicked(subletType) {
      this.activeTab = subletType
      this.getAvailableSublets()
    }
  }
}
</script>

<style lang="scss" scoped>
.svg path {
  fill: red !important;
  &:hover path {
    fill: white !important;
  }
}
</style>
